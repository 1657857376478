.projects-container {
	padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 3em;
}
.projeto_nome{
    color: #3b82f6;
    text-decoration: underline;
    font-size: 1.25em;
    cursor: pointer;
}
.projeto__cover-container{
    position: relative;
}
.links__icons{
    font-size:  1.25em;
    padding-left: 10px;
}
.links__icons:hover{
    transition: 300ms;
    color: #728495;
}
.projeto__cover {
    border-radius: 5px;
    position: relative;
	aspect-ratio: 16/9;
	width: 100%;
}
