.intro {
	height: 100vh;
	color: white;
	text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
}
.intro__container {
	display: flex;
	position: relative;
	top: 50%;
	left: 50%;
	translate: -50% -50%;
	align-items: center;
	justify-content: space-evenly;
}
.intro h1 {
	font-size: 1.5em;
	text-transform: uppercase;
}
.intro p {
	font-size: 4em;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: -0.02em;
	line-height: 1.1em;
}
.intro__icons {
	padding: 5px 10px;
	font-size: 0.5em;
	transition: 300ms;
}
.intro__icons:hover {
	color: #ffffff80;
	cursor: pointer;
	translate: 0 -10px;
}
.react-icon {
	color: rgb(240, 240, 240);
	font-size: 10em;
}
.react-icon-container {
	display: flex;
	position: relative;
}
.react-icon-container::before {
	position: absolute;
	border-radius: 100%;
	content: "";
	width: 100%;
	height: 100%;
	background-color: white;
	opacity: 1;
	filter: blur(3.5rem);
}

@media only screen and (max-width: 768px) {
	.react-icon-container{
		display: none;
	}
	.intro h1 {
		font-size: 1em;
	}
	.intro p {
		font-size: 2.5em;
	}
}

