* {
	margin: 0;
	padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
	box-sizing: border-box;
}
a{
  text-decoration: none;
  color: inherit;
}
.App{
  display: flex;
  background-color: #7fc3fe;
  min-height: 100vh;
  color: #3b454e;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}
.container{
  align-self: center;
  margin: 15px;
  max-width: 700px;
  border-radius: 10px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.362);
  background-color: #fafafa;
  padding: 15px;
}.container p{
  text-indent: 2em;
  margin: 10px 0px;
  line-height: 1.5em; 
  text-align: justify;
}
.footer{
  text-align: center;
  padding: 10px;
  color: #a0b1c5;
  background-color: #11161d;
}
.footer__socials{
  cursor: pointer;
  font-size: 2em;
  padding: 10px;
}
.footer__socials:hover{
  transition: 300ms;
  color: #cbe4ff;
}
.message{
	font-weight: bold;
	position: fixed;
	top: 20px;
	left: 50%;
	padding: 15px;
  color: #a0b1c5;
  background-color: #11161d;
	border: 1px solid #263141;
	border-radius: 4px;
	translate: -50%;
	width: 420px;
	max-width: 80vw;
}
