.menu {
	color: #5c728a;
	position: fixed;
	bottom: 25px;
	right: 20px;
	z-index: 999;
	cursor: pointer;
}
.mobile_menu {
	display: flex;
	width: 50px;
	height: 50px;
	border-radius: 6px;
	box-shadow: 0 1px 20px rgba(0, 0, 0, 1);
	background-color: #fafafa;
	align-items: center;
	justify-content: center;
}

.navbar {
    list-style: none;
	width: 0px;
	height: 0px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(2, 1fr);
	opacity: 0;
	pointer-events: none;
	box-shadow: 0 1px 20px #0000004d;
	border-radius: 5px;
	justify-items: center;
	align-items: center;
	padding: 8px;
}
.mobile--visible {
	pointer-events: auto;
	height: 150px;
	width: 230px;
	opacity: 1;
	transition: 0.2s;
	background-color: #fafafa;
}
.navbar__button {
	color: #5c728a;
	text-align: center;
	font-weight: bold;
}
.navbar__label {
	white-space: nowrap;
	font-size: 0.8em;
	display: block;
}

@media only screen and (min-width: 1040px) {
    .menu {
        color: #5c728a;
        position: relative;
        bottom: 0px;
        left: 0px;
        cursor: auto;
    }
	.navbar {
		display: flex;
		width: 100%;
		height: auto;
		padding: 15px;
		border-radius: 0;
		box-shadow: none;
		color: white;
		background-color: #517ca2;
		justify-content: space-evenly;
        opacity: 1;
		pointer-events: auto;
	}
    .navbar__label{
        color: white;
    }
    .navbar__label:hover{
		text-decoration: underline;
    }
    .navbar__icon{
        display: none;
    }
}
